<template>
  <div class="user-info-configuration">
    <div class="user-info-configuration-container">
      <el-menu
        class="user-info-configuration-container-menu"
        :default-active="defaultActive"
        @select="handleSelect"
      >
        <el-menu-item
          v-for="item in menuList"
          :key="item.id"
          :index="item.id"
          >{{ item.name }}</el-menu-item
        >
      </el-menu>
      <div class="user-info-configuration-container-content">
        <!-- 必填字段 -->
        <div class="user-info-configuration-container-content-required">
          <div class="user-info-configuration-container-content-required-title">
            必填字段
          </div>
          <div
            class="user-info-configuration-container-content-required-content"
          >
            <div
              class="user-info-configuration-container-content-required-content-item"
              v-for="item in form.must"
              :key="item.id"
            >
              <el-switch
                v-model="item.required"
                :disabled="item.disabled"
                @change="handleRequiredChange(item)"
              />
              <div
                class="user-info-configuration-container-content-required-content-item-text"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
        <!-- 可配置字段 -->
        <div class="user-info-configuration-container-content-optional">
          <div class="user-info-configuration-container-content-optional-title">
            可配置字段
          </div>
          <div
            class="user-info-configuration-container-content-optional-content"
          >
            <draggable
              v-model="form.optional"
              :options="{
                group: 'optional',
                animation: 200,
                handle: '.el-icon-rank',
              }"
              chosenClass="chosen"
              @change="handleChange"
            >
              <div
                class="user-info-configuration-container-content-optional-content-item"
                v-for="item in form.optional"
                :key="item.id"
              >
                <div
                  class="user-info-configuration-container-content-optional-content-item-switch"
                >
                  <el-switch
                    v-model="item.show"
                    @change="handleShowChange(item)"
                  />
                  <div
                    class="user-info-configuration-container-content-optional-content-item-switch-text"
                  >
                    {{ item.text }}
                  </div>
                </div>
                <div
                  class="user-info-configuration-container-content-optional-content-item-required"
                >
                  必填项：
                  <el-radio-group
                    class="user-info-configuration-container-content-optional-content-item-required-radio"
                    v-model="item.required"
                    @change="handleRequiredChange(item)"
                  >
                    <el-radio-button :label="true">是</el-radio-button>
                    <el-radio-button :label="false">否</el-radio-button>
                  </el-radio-group>
                  <!-- draggable作用在这个按钮上 -->
                  <i
                    class="el-icon-rank user-info-configuration-container-content-optional-content-item-required-icon"
                  ></i>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ElMenu, ElMenuItem } from "element-ui";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },

  data() {
    return {
      menuList: [
        {
          name: "睡眠管理平台",
          id: "1",
        },
        {
          name: "OSA平板",
          id: "2",
        },
        {
          name: "睡眠管理app",
          id: "3",
        },
        {
          name: "问卷筛查用户页",
          id: "4",
        },
      ],
      defaultActive: "1",
      form: {
        must: [],
        optional: [],
      },
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  watch: {
    hospitalID: function () {
      this.getFieldList();
    },
  },

  created() {
    this.getFieldList();
  },

  methods: {
    // 获取字段配置列表
    async getFieldList() {
      try {
        const res = await this.$api.getUserFieldConfigList({
          hospitalId: this.hospitalID,
          platformType: Number(this.defaultActive),
        });
        if (res) {
          this.formatFieldList(res);
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 格式化接口数据
    formatFieldList(data) {
      const must = [];
      const optional = [];

      data.forEach((item) => {
        const field = {
          id: item.id,
          fieldCode: item.fieldCode,
          text: item.fieldName,
          required: item.fieldType === 1 ? true : item.fieldIsRequired === 1,
          show: item.fieldShow === 1,
          index: item.sortOrder,
          disabled: item.fieldType === 1,
        };

        if (item.fieldType === 1) {
          must.push(field);
        } else {
          optional.push(field);
        }
      });

      this.form.must = must;
      this.form.optional = optional.sort((a, b) => a.index - b.index);
    },

    // 菜单选择
    handleSelect(key) {
      this.defaultActive = key;
      this.getFieldList();
    },

    // 拖拽排序变化
    async handleChange(e) {
      if (!e.moved) return;
      const sortOrder = this.form.optional.map((item, index) => ({
        id: item.id,
        sort: index,
      }));
      try {
        await this.$api.moveUserFieldConfig(sortOrder);
        // 更新排序索引
        this.form.optional.forEach((item, index) => {
          item.index = index;
        });
      } catch (err) {
        console.error(err);
        // 拖拽失败,还原列表
        this.getFieldList();
      }
    },

    // 必填状态变化
    async handleRequiredChange(item) {
      try {
        await this.$api.updateUserFieldConfig({
          id: item.id,
          fieldType: item.disabled ? 1 : 2,
          fieldIsRequired: item.required ? 1 : 0,
          fieldShow: item.show ? 1 : 0,
        });
        // 更新成功后重新获取列表
        this.getFieldList();
      } catch (err) {
        console.error(err);
        // 更新失败还原状态
        item.required = !item.required;
      }
    },
    async handleShowChange(item) {
      try {
        await this.$api.updateUserFieldConfig({
          id: item.id,
          fieldType: item.disabled ? 1 : 2,
          fieldIsRequired: item.required ? 1 : 0,
          fieldShow: item.show ? 1 : 0,
        });
        // 更新成功后重新获取列表
        this.getFieldList();
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info-configuration {
  background-color: white;
  border-radius: rem(2);
  width: 100%;
  height: 100%;

  &-container {
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;
    box-sizing: border-box;
    padding: rem(87) rem(36) rem(50);
    display: flex;

    &-menu {
      width: 160px;
      height: 100%;
    }

    &-content {
      flex: 1;
      overflow: hidden;
      // overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 30px 0;

      &-required {
        overflow: hidden;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;
        &-title {
          font-size: 16px;
          font-weight: 600;
          color: #333;
          margin-bottom: 15px;
        }
        &-content {
          flex: 1;
          overflow: hidden;
          // overflow-y: auto;
          display: flex;
          &-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            margin-right: 25px;
            &-text {
              margin-left: 10px;
              font-size: 14px;
              color: #000;
            }
          }
        }
      }

      &-optional {
        flex: 1;
        overflow: hidden;
        // overflow-y: auto;
        display: flex;
        flex-direction: column;
        &-title {
          font-size: 16px;
          font-weight: 600;
          color: #333;
          margin-bottom: 15px;
        }
        &-content {
          flex: 1;
          overflow: hidden;
          // overflow-y: auto;
          display: flex;
          flex-direction: column;
          &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            width: 450px;
            &-switch {
              display: flex;
              align-items: center;
              justify-content: space-between;
              &-text {
                margin-left: 20px;
                font-size: 14px;
                color: #000;
              }
            }
            &-required {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 14px;
              color: #000;

              &-radio {
                .el-radio-button {
                  ::v-deep(.el-radio-button__inner) {
                    padding: 5px 17px;
                  }
                }
              }
              &-icon {
                margin-left: 10px;
                font-size: 16px;
                color: #a7a7a7;
                &:hover {
                  color: #3089dc;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
.chosen {
  border: solid 2px #3089dc !important;
}
</style>
